import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5JZdsjP45mCYOdCaV-bkmexkgiQFjsKE",
  authDomain: "clipship-7star.firebaseapp.com",
  projectId: "clipship-7star",
  storageBucket: "clipship-7star.appspot.com",
  messagingSenderId: "935745042782",
  appId: "1:935745042782:web:66d7dfc79e4c88009db7cf",
  measurementId: "G-YBLXBM87RC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
